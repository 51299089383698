<template>
<img class="banner" src="../assets/images/faqs.png" />
<div class="view">
    <div class="page-title">{{ policy.title }}</div>
    <div v-for="(section, index) in policy.sections" :key="index"
        class="section">
        <div class="section-title">{{section.title ? section.title : "---"}}</div>
        <div v-for="(line, lindex) in section.details" :key="lindex"
            class="section-line">
            <span v-html="line"></span>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: [ "policy" ]
}
</script>


<style scoped>

.banner {
    @apply hidden z-0 h-64 mt-28 w-64 mr-32
        md:absolute md:block md:self-center md:opacity-30;
    right: 50%;
}

.view {
    @apply flex flex-col px-4 py-8 font-nunito text-xs
        md:max-w-screen-sm md:self-center md:mt-10 md:z-10;
}

.page-title {
    @apply font-nunito text-ccb-amber-400 text-sm
        md:text-ccb-amber-400 md:text-lg md:mb-16 md:font-semibold;
}

.section {
    @apply mt-6;
}

.section-title {
    @apply font-black;
}

.section-line {
    @apply mt-2;
}

</style>